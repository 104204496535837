<template>
  <div class="p-3 bg-white px-4">
    <div class="d-flex align-items-center border-bottom pb-2 mb-4">
      <a class="fs-18 txt-grey-600" @click.prevent="backToMdtDetail">
        {{ room ? room.name : "" }}
        <span class="spinner-border" role="status" v-if="!room">
          <span class="sr-only">Loading...</span>
        </span>
      </a>
      <div>
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="mx-2"
        >
          <path
            d="M8.29492 17.09L12.8749 12.5L8.29492 7.91L9.70492 6.5L15.7049 12.5L9.70492 18.5L8.29492 17.09Z"
            fill="#667085"
          />
        </svg>
        <a href="javascript:;" class="fs-18 txt-grey-900 mr-2"
          >{{
            this.$route.params.type == 0
              ? "Ca bệnh hội chẩn chuyển khoa đã kết thúc"
              : this.$route.params.type == 1
              ? "Ca bệnh thông qua mổ đã kết thúc"
              : ""
          }}
          ({{ cases && cases.total }})</a
        >
      </div>
    </div>
    <div class="mb-3">
      <div class="input-group radius-12 border">
        <div class="input-group-text bg-white radius-12 border-0">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.7104 20.2894L18.0004 16.6094C19.4405 14.8138 20.1379 12.5347 19.9492 10.2407C19.7605 7.94672 18.7001 5.8122 16.9859 4.27606C15.2718 2.73992 13.0342 1.91893 10.7333 1.98189C8.43243 2.04485 6.24311 2.98698 4.61553 4.61456C2.98795 6.24214 2.04582 8.43145 1.98286 10.7323C1.9199 13.0332 2.7409 15.2708 4.27704 16.9849C5.81318 18.6991 7.94769 19.7595 10.2417 19.9482C12.5357 20.1369 14.8148 19.4395 16.6104 17.9994L20.2904 21.6794C20.3834 21.7731 20.494 21.8475 20.6158 21.8983C20.7377 21.9491 20.8684 21.9752 21.0004 21.9752C21.1324 21.9752 21.2631 21.9491 21.385 21.8983C21.5068 21.8475 21.6174 21.7731 21.7104 21.6794C21.8906 21.493 21.9914 21.2438 21.9914 20.9844C21.9914 20.7251 21.8906 20.4759 21.7104 20.2894ZM11.0004 17.9994C9.61592 17.9994 8.26255 17.5889 7.1114 16.8197C5.96026 16.0505 5.06305 14.9573 4.53324 13.6782C4.00342 12.3991 3.8648 10.9917 4.1349 9.63378C4.40499 8.27592 5.07168 7.02864 6.05065 6.04967C7.02961 5.0707 8.27689 4.40402 9.63476 4.13392C10.9926 3.86382 12.4001 4.00245 13.6792 4.53226C14.9583 5.06207 16.0515 5.95928 16.8207 7.11042C17.5899 8.26157 18.0004 9.61495 18.0004 10.9994C18.0004 12.8559 17.2629 14.6364 15.9501 15.9492C14.6374 17.2619 12.8569 17.9994 11.0004 17.9994Z"
              fill="#475467"
            />
          </svg>
        </div>
        <input
          type="text"
          class="form-control bg-white radius-12"
          v-model="search"
          @keyup.enter="getPublicMdtCases()"
          placeholder="Nhập tên Bác sĩ, Bệnh nhân"
        />
      </div>
    </div>
    <div class="mb-3">
      <div class="table-responsive">
        <table class="table table-vcenter table-mobile-md card-table">
          <thead>
            <tr>
              <th>Workspace</th>
              <th>Bệnh nhân</th>
              <th>Chẩn đoán</th>
              <th>Thời gian đăng ký</th>
              <!-- <th>Bác sĩ phụ trách</th> -->
              <th>Trạng thái</th>
              <th>BB Hội Chẩn</th>
              <th>TQ Mổ</th>
            </tr>
          </thead>
          <tbody
            v-for="date in filteredAndSorted"
            :key="date.date + 'date-group'"
          >
            <tr class="">
              <td colspan="7" class="py-4 border-0">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="mr-2"
                  :class="!date.show ? 'upsidedown' : ''"
                >
                  <path d="M7 9.5L12 14.5L17 9.5H7Z" fill="#1D2939" />
                </svg>
                <span class="fs-16 fw-600 mb-0 txt-grey-900">{{
                  date.date
                }}</span>
              </td>
            </tr>
            <tr
              v-for="d in date.cases"
              :key="d.id"
              @click="goToCase(d.id, $event)"
              class="cursor-pointer"
              v-show="date.show"
            >
              <td data-label="Workspace" class="border-bottom">
                <p class="fs-14 fw-600 txt-grey-900 mb-0">
                  {{ d.clinic && d.clinic.name }}
                </p>
              </td>
              <td data-label="Bệnh nhân" class="border-bottom">
                <p class="fs-14 fw-600 txt-grey-900 mb-0">
                  {{ d.patient_name }}
                </p>
                <p class="fs-12 txt-grey-600 mb-0">
                  ({{ mdtUtils.getAges(d.patient_birthday)
                  }}{{ d.patient_address ? " - " + d.patient_address : "" }})
                </p>
              </td>
              <td data-label="Chẩn đoán" class="border-bottom">
                <p class="fs-14 fw-500 txt-grey-900 mb-0">
                  {{ d.patient_diagnostic }}
                </p>
              </td>
              <td data-label="Thời gian đăng ký" class="border-bottom">
                <p class="fs-14 fw-500 txt-grey-700 mb-0">
                  {{ formatDateDMYHm(d.created_at) }}
                </p>
              </td>
              <!-- <td data-label="Bác sĩ phụ trách" class="border-bottom">
                <p class="fs-14 fw-500 txt-grey-700 mb-0">
                  {{ d.doctor && d.doctor.name }}
                </p>
              </td> -->
              <td
                data-label="Trạng thái"
                :set="(sttClass = mdtUtils.getStatusPublicMdtCase(d.status))"
                class="border-bottom"
              >
                <p class="fs-16 fw-500 mb-0" :class="sttClass.class">
                  {{ sttClass.text }}
                </p>
              </td>
              <td data-label="BB Hội Chẩn" class="border-bottom">
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  v-if="d.status == 0"
                >
                  <path
                    d="M20 5C11.72 5 5 11.72 5 20C5 28.28 11.72 35 20 35C28.28 35 35 28.28 35 20C35 11.72 28.28 5 20 5ZM17 26.75V13.25L26 20L17 26.75Z"
                    fill="#20409B"
                  />
                </svg>

                <div class="d-flex" v-if="d.concluding && d.concluding.pdf">
                  <svg
                    width="33"
                    height="32"
                    viewBox="0 0 33 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-2 cursor-pointer"
                    @click="open(d.concluding.pdf, $event)"
                  >
                    <path
                      d="M16.4997 11.4167C19.658 11.4167 22.4747 13.1917 23.8497 16C22.4747 18.8083 19.6663 20.5833 16.4997 20.5833C13.333 20.5833 10.5247 18.8083 9.14967 16C10.5247 13.1917 13.3413 11.4167 16.4997 11.4167ZM16.4997 9.75C12.333 9.75 8.77467 12.3417 7.33301 16C8.77467 19.6583 12.333 22.25 16.4997 22.25C20.6663 22.25 24.2247 19.6583 25.6663 16C24.2247 12.3417 20.6663 9.75 16.4997 9.75ZM16.4997 13.9167C17.6497 13.9167 18.583 14.85 18.583 16C18.583 17.15 17.6497 18.0833 16.4997 18.0833C15.3497 18.0833 14.4163 17.15 14.4163 16C14.4163 14.85 15.3497 13.9167 16.4997 13.9167ZM16.4997 12.25C14.433 12.25 12.7497 13.9333 12.7497 16C12.7497 18.0667 14.433 19.75 16.4997 19.75C18.5663 19.75 20.2497 18.0667 20.2497 16C20.2497 13.9333 18.5663 12.25 16.4997 12.25Z"
                      fill="#20409B"
                    />
                    <rect
                      x="1.25"
                      y="0.75"
                      width="30.5"
                      height="30.5"
                      rx="7.25"
                      stroke="#20419B"
                      stroke-width="1.5"
                    />
                  </svg>
                  <svg
                    width="33"
                    height="32"
                    viewBox="0 0 33 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="cursor-pointer"
                    @click="print(d.concluding.pdf, $event)"
                  >
                    <path
                      d="M22.3337 12.6667H21.5003V8.5H11.5003V12.6667H10.667C9.28366 12.6667 8.16699 13.7833 8.16699 15.1667V20.1667H11.5003V23.5H21.5003V20.1667H24.8337V15.1667C24.8337 13.7833 23.717 12.6667 22.3337 12.6667ZM13.167 10.1667H19.8337V12.6667H13.167V10.1667ZM19.8337 21.8333H13.167V18.5H19.8337V21.8333ZM21.5003 18.5V16.8333H11.5003V18.5H9.83366V15.1667C9.83366 14.7083 10.2087 14.3333 10.667 14.3333H22.3337C22.792 14.3333 23.167 14.7083 23.167 15.1667V18.5H21.5003Z"
                      fill="#20409B"
                    />
                    <path
                      d="M21.5003 16.4167C21.9606 16.4167 22.3337 16.0436 22.3337 15.5833C22.3337 15.1231 21.9606 14.75 21.5003 14.75C21.0401 14.75 20.667 15.1231 20.667 15.5833C20.667 16.0436 21.0401 16.4167 21.5003 16.4167Z"
                      fill="#20409B"
                    />
                    <rect
                      x="1.25"
                      y="0.75"
                      width="30.5"
                      height="30.5"
                      rx="7.25"
                      stroke="#20419B"
                      stroke-width="1.5"
                    />
                  </svg>
                </div>
              </td>
              <td data-label="TQ Mổ" class="border-bottom">
                <div class="d-flex" v-if="d.operation && d.operation.pdf">
                  <svg
                    width="33"
                    height="32"
                    viewBox="0 0 33 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-2 cursor-pointer"
                    @click="open(d.operation.pdf, $event)"
                  >
                    <path
                      d="M16.4997 11.4167C19.658 11.4167 22.4747 13.1917 23.8497 16C22.4747 18.8083 19.6663 20.5833 16.4997 20.5833C13.333 20.5833 10.5247 18.8083 9.14967 16C10.5247 13.1917 13.3413 11.4167 16.4997 11.4167ZM16.4997 9.75C12.333 9.75 8.77467 12.3417 7.33301 16C8.77467 19.6583 12.333 22.25 16.4997 22.25C20.6663 22.25 24.2247 19.6583 25.6663 16C24.2247 12.3417 20.6663 9.75 16.4997 9.75ZM16.4997 13.9167C17.6497 13.9167 18.583 14.85 18.583 16C18.583 17.15 17.6497 18.0833 16.4997 18.0833C15.3497 18.0833 14.4163 17.15 14.4163 16C14.4163 14.85 15.3497 13.9167 16.4997 13.9167ZM16.4997 12.25C14.433 12.25 12.7497 13.9333 12.7497 16C12.7497 18.0667 14.433 19.75 16.4997 19.75C18.5663 19.75 20.2497 18.0667 20.2497 16C20.2497 13.9333 18.5663 12.25 16.4997 12.25Z"
                      fill="#20409B"
                    />
                    <rect
                      x="1.25"
                      y="0.75"
                      width="30.5"
                      height="30.5"
                      rx="7.25"
                      stroke="#20419B"
                      stroke-width="1.5"
                    />
                  </svg>
                  <svg
                    width="33"
                    height="32"
                    viewBox="0 0 33 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="cursor-pointer"
                    @click="print(d.operation.pdf, $event)"
                  >
                    <path
                      d="M22.3337 12.6667H21.5003V8.5H11.5003V12.6667H10.667C9.28366 12.6667 8.16699 13.7833 8.16699 15.1667V20.1667H11.5003V23.5H21.5003V20.1667H24.8337V15.1667C24.8337 13.7833 23.717 12.6667 22.3337 12.6667ZM13.167 10.1667H19.8337V12.6667H13.167V10.1667ZM19.8337 21.8333H13.167V18.5H19.8337V21.8333ZM21.5003 18.5V16.8333H11.5003V18.5H9.83366V15.1667C9.83366 14.7083 10.2087 14.3333 10.667 14.3333H22.3337C22.792 14.3333 23.167 14.7083 23.167 15.1667V18.5H21.5003Z"
                      fill="#20409B"
                    />
                    <path
                      d="M21.5003 16.4167C21.9606 16.4167 22.3337 16.0436 22.3337 15.5833C22.3337 15.1231 21.9606 14.75 21.5003 14.75C21.0401 14.75 20.667 15.1231 20.667 15.5833C20.667 16.0436 21.0401 16.4167 21.5003 16.4167Z"
                      fill="#20409B"
                    />
                    <rect
                      x="1.25"
                      y="0.75"
                      width="30.5"
                      height="30.5"
                      rx="7.25"
                      stroke="#20419B"
                      stroke-width="1.5"
                    />
                  </svg>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '../../utils/appUtils'
import mdtUtils from '../../utils/mdtUtils'
import moment from 'moment'
export default {
  name: 'OrganizationMdtListDisease',
  data () {
    return {
      room: null,
      session: null,
      search: '',
      mdtUtils,
      url_pdf: '',
      show_ts: true,
      show_mdt: true,
      cases: [],
      loading_case: false
    }
  },
  mounted () {
    if (this.$route.params.id && (parseInt(this.$route.params.type) === 0 || parseInt(this.$route.params.type) === 1)) {
      this.showPublicMdtRoom()
      this.getPublicMdtCases()
    }
  },
  computed: {
    filteredAndSorted () {
      if (!this.cases?.data) return []
      const groups = this.cases.data.reduce((groups, mdtCase) => {
        const date = mdtCase.mdt_date
        if (!groups[date]) {
          groups[date] = []
        }
        groups[date].push(mdtCase)
        return groups
      }, {})

      // Edit: to add it in the array format instead
      const groupArrays = Object.keys(groups).map((date) => {
        return {
          date,
          cases: groups[date],
          show: true
        }
      })
      return groupArrays
    }
  },
  methods: {
    async getPublicMdtCases (is_next) {
      this.loading_case = true
      let page = 1
      if (!is_next) {
        this.cases = []
      } else {
        page = this.cases.current_page + 1
      }
      let params = {
        public_mdt_id: this.$route.params.id,
        limit: 30,
        sort: 'mdt_date',
        sort_by: 'desc',
        page: page,
        statuses: [1, 2, 3],
        type: this.$route.params.type
      }
      if (this.search) {
        params.search = this.search
      }

      try {
        const res = await this.$rf.getRequest('DoctorRequest').getPublicMdtCases(params)
        if (res && res.data) {
          this.cases.current_page = res.data.current_page
          if (!is_next) {
            this.cases = res.data
          } else {
            if (res.data?.count) {
              let old = this.cases.data
              let newObj = res.data.data
              this.cases.data = old.concat(newObj)
            }
          }
        }
      } finally {
        this.loading_case = false
      }
    },
    async showPublicMdtRoom () {
      const res = await this.$rf.getRequest('DoctorRequest').getPublicMtdRoomDetails(this.$route.params.id)
      this.room = res.data
    },
    formatDate (date) {
      return moment(date).format('DD/MM/YYYY')
    },
    formatDateDMYHm (dt) {
      return moment(dt).format('DD/MM/YYYY HH:mm')
    },
    backToMdtDetail () {
      this.$router.push({ name: 'ListMdt', query: { mdtid: this.room?.id, type: 'ORG' } })
    },
    open (url, e) {
      e.stopPropagation()
      window.open(url)
    },
    print (url, e) {
      e.stopPropagation()
      window.open(url)
    },
    goToRoom (r) {
      let dm = ''
      if (process.env.NODE_ENV === 'development') {
        dm = 'https://dev.meet.hodo.com.vn/#/'
      } else {
        dm = 'https://meet.hodo.com.vn/#/'
      }
      let token = appUtils.getLocalToken()
      let routeData = dm + '_pmdt/' + r?.room?.room_name + '?token=' + token
      window.open(routeData, '_blank')
    },
    goToCase (id) {
      this.$router.push({ name: 'OrganizationMdtDiseaseDetail', params: { id: id } })
    }
  }
}
</script>

<style scoped>
.upsidedown {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}
</style>